import React from "react";

const Footer = () => {
    return (
        <div className="texte white bc-black center padd30-5">
            Adrien Meynard - Fruition Sciences
        </div>
    )
}

export default Footer