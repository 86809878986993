import React, { useEffect, useState } from "react";

const Chemin = (props) => {
  const [cheminAspirateur, setCheminAspirateur] = useState("");

  useEffect(() => {
    props.cheminAspirateurRender(cheminAspirateur);
  }, [cheminAspirateur]);

  return (
    <div>
      <p className="sousTitle">Chemin de l'aspirateur (ex : ADAGA)</p>
      <input
        type="text"
        placeholder="A : avance | D : 90° à droite  | G : 90° à  gauche"
        onChange={(e) => setCheminAspirateur(e.target.value)}
        style={{ width: "430px" }}
      />
    </div>
  );
};

export default Chemin;
